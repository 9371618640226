<template>
    <div>
      <SiteHeader></SiteHeader>
  
      <div class="topbar text-center">
        <strong class="fs-1">Your shipment is getting closer to you.</strong>
        <div class="menu">
            <div class="alink" @click="toCart">My Cart</div>
            <div class="alink current" @click="toOrders">Orders</div>
            <div class="alink" @click="toAccount">Account</div>
            <div class="alink" @click="toMessages">Messages</div>
            <div class="alink" @click="toAbout">About</div>
        </div>
      </div>
  
      <div class="container mt-5 mb-5">
        <div class="tracking">


          <div class="tracking-header" v-if="detail[0]">
            <h3>Order Shipping Tracking  <el-button type="primary" icon="el-icon-bell" @click="getDetail" size="mini">Subscribe</el-button></h3>
            <el-row :gutter="10">
              <el-col :span="8" class="mb-0">
                <p class="mb-2"><i class="bi bi-receipt"></i><span>Shipping Order: {{ detail[0].tracking_number }}</span></p>
                <p class="mb-2"><i class="bi bi-geo-alt"></i><span>From: {{ detail[0].original }}</span></p>
                
                <p class="mb-2"><i class="bi bi-truck"></i><span>Weight: {{ detail[0].weight }}</span></p>
                
              </el-col>
              <el-col :span="8" class="mb-0">
                <p class="mb-2"><i class="bi bi-building"></i><span>Shipping Company: <abbr style="text-transform: uppercase">{{ detail[0].courier_code }}</abbr></span></p>
                <p class="mb-2"><i class="bi bi-geo-alt-fill"></i><span>To: {{ detail[0].destination }}</span></p>
                <p class="mb-2"><i class="bi bi-boxes"></i><span>Service: {{ detail[0].service_code }}</span></p>
              </el-col>
              <el-col :span="8" class="mb-0">
                <p class="mb-2"><i class="bi bi-truck"></i><span>Status: {{ detail[0].delivery_status }}</span></p>
                <p class="mb-2"><i class="bi bi-clock-history"></i><span>Created At: {{ formatDate(detail[0].created_at) }}</span></p>
                <p class="mb-2"><i class="bi bi-clock"></i> <span>Last Time:{{ formatDate(detail[0].lastest_checkpoint_time) }}</span></p>

              </el-col>
              <el-col :span="24" class="mb-0">
                <p class="mb-2"><i class="bi bi-pencil-square"></i><span>Last Update: {{ detail[0].latest_event }}</span></p>
              </el-col>
            </el-row>

          </div>





            <div class="block">

                <el-timeline>
                  <el-timeline-item v-for="(info, index) in trackInfo" :key="index" :timestamp="info.checkpoint_date" placement="top" >
                    <el-card>
                      <h4>{{ info.tracking_detail }}</h4>
                      <p class="mb-0">Location: {{ info.location }}</p>
                      <span>{{ info.country_iso2 }}</span>
                    </el-card>
                    
                  </el-timeline-item>
                </el-timeline>

                </div>
   
        </div>

      </div>
  
      <Foot></Foot>
    </div>
  </template>
  
  <script>
  import SiteHeader from "@/components/website/SiteHeader.vue";
  
  import Foot from "@/components/website/Foot.vue";
  import { trackByNumber } from "@/api/index.js";
  
  export default {
    name: "PcWebsiteIndex",
  
    components: {
      SiteHeader,
      Foot,
    },
  

    data() {
      return {
        detail: {},
        trackInfo: [],
        dimensionString: '', // 添加一个新的变量来保存处理后的 dimension 字符串
      };
    },
  
    computed: {
      token: {
        get() {
          return this.$store.state.user.token;
        },
      },
      userinfo: {
        get() {
          return this.$store.state.user.userinfo;
        },
      },
    },
  
    watch: {},
    mounted() {
      this.getDetail();
    },
    methods: {
      getDetail() {
        const trackingNumber = '775777625188'; // 设置要跟踪的号码
        trackByNumber(trackingNumber).then(
          (res) => {
            //console.log(res); // 添加调试语句
            this.detail = res.data;
            if (res.data && res.data.length > 0) {

              const TrackingData = res.data[0];

              //console.log(TrackingData); // 添加调试语句
              if (TrackingData.origin_info && TrackingData.origin_info.trackinfo) {
                // 提取事件数据并更新到 trackInfo 中
                this.trackInfo = TrackingData.origin_info.trackinfo;



              } else {
                console.error('No tracking data found or invalid response structure');
              }
            } else {
              console.error('No tracking data found or invalid response structure');
            }
          }
        ).catch((error) => {
          console.error('Error fetching tracking data:', error);
          this.$router.push('/order/list');
        });
      },

      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString();
      },
    toCart() {
      this.$router.push("/cart");
    },
    toOrders() {
      this.$router.push("/order/list");
    },
    toAccount() {
      this.$router.push("/account");
    },
    toMessages() {
      this.$router.push("/message");
    },
    toAbout() {
      this.$router.push("/about");
    },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/styles/variables.scss";
  .tracking-header {
    background-color: var(--el-bg-color-page);
    padding: 1rem;
    font-size:1rem;
    color: var(--span-color);
    margin-bottom: 2rem;
    h3 {
      display: block;
      border-bottom: 1px solid var(--el-border-color);
      padding-bottom: 0.8rem;
      margin-bottom: 0.8rem;
      align-items: center;
      .el-button {
        float:right;
      }
    }
    i.bi {
      margin-right:3px;
    }
  }
  .el-timeline {
    margin-left:0px;
    padding-left:0px;
  }
  </style>